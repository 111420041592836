@import "./assets/styles/colors";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100vw;
  height: 100vh;
  background-color: $c-primary;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Nunito', sans-serif;
}

button {
  cursor: pointer;
}

li {
  list-style: none;
}

h1 {
  margin-bottom: 12px;
}

.App {
  display: flex;
  flex-direction: column;
  width: 600px;
  height: 450px;
  background-color: $c-white;
  border: 2px solid $c-black;
  border-radius: 10px;
  box-shadow: 5px 5px $c-black;
  overflow: scroll;
}

.App::-webkit-scrollbar {
  width: 0.5em; /* 스크롤바 너비 조절 */
}

.App::-webkit-scrollbar-track {
  background: transparent; /* 스크롤바 트랙 배경 투명하게 */
}

.App::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1); /* 스크롤바 색상 및 투명도 조절 */
  border-radius: 0.25em; /* 스크롤바 둥글게 */
}
