@import "../assets/styles/colors";

.toolbar {
  display:flex;
  justify-content: space-between;
  align-items:center;
  width: 100%;
  padding: 8px;
  border-bottom: 2px solid $c-black;

  .toolbar__actables {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 6px;

    button {
      padding: 4px 8px;
      border: 2px solid $c-black;
      border-radius: 4px;
      background-color: $c-white;

      &:hover {
        background-color: $c-primary;
      }
    }

    .actables__url {
      width: 320px;
      padding: 2px 8px;
      height: 27px;
      border: 2px solid $c-black;
      border-radius: 4px;
      background-color: $c-white;
      font-size: 14px;
    }
  }

  .toolbar__deco {
    position: relative;
    .deco__circle {
      width: 20px;
      height: 20px;
      border: 2px solid $c-black;
      border-radius: 50%;

      &::after, &::before {
        display: block;
        box-sizing: border-box;
        position: absolute;
        top: 0px;
        content: "";
        width: 20px;
        height: 20px;
        border: 2px solid $c-black;
        border-radius: 50%;
      }

      &::after {
        left: -26px;
      }

      &::before {
        left: -52px;
      }

    }
  }
}